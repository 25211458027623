export const ExtraData = [
    {
        id:1,
        title: "Silambam",
        image: "/Images/English/Extra/Silambam.jpeg",
    },
    {
        id:2,
        title: "Yoga",
        image: "/Images/English/Extra/Yoga.jpeg",
    },
    {
        id:3,
        title: "Karate",
        image: "/Images/English/Extra/Karate.jpeg",
    },
    {
        id:4,
        title: "Skating",
        image: "/Images/English/Extra/Skating.jpeg",
    },
    {
        id:5,
        title: "Bharatham",
        image: "/Images/English/Extra/Bharatham.jpeg",
    },
    {
        id:6,
        title: "Music",
        image: "/Images/English/Extra/Music.jpeg",
    },
];