export const DataMessage = [
    {
        id:1,
        image:"/Images/Home/Message/Provincial1.jpeg",
        from:"Province",
        msgTitle:"Dear Students, Parents, and Well-Wishers,",
        message:"At Don Bosco, we strive to nurture the values of discipline, excellence, and service to humanity. As a province committed to education and social development, we take pride in empowering young minds to become compassionate and responsible leaders. Together, let us build a brighter future through learning and mutual respect.",
        regards:"Warm regards,",
        name:"Rev.Fr.Agilan Sarprasadam SDB",
        position:"Provincial, Salesian Province of Trichy",
    },
    {
        id:2,
        image:"/Images/Common/Management/Correspondent.jpg",
        from:"Correspondent",
        msgTitle:"Dear Don Bosco Family,",
        message:"It is my pleasure to welcome you to Don Bosco School. Our mission is to provide holistic education rooted in values, aiming for academic excellence and personal growth. With the support of dedicated staff and enthusiastic students, we continue to foster an environment of creativity, curiosity, and character development. Thank you for being part of this journey. Let us work together for the success of our students and community.",
        regards:"Sincerly,",
        name:"Rev. Fr. Susai Savariana, SDB",
        position:"Rector & Correspondent",
    },
    {
        id:3,
        image:"/Images/Common/Management/Father2.JPG",
        from:"Headmaster",
        msgTitle:"Dear Parents and Students,",
        message:"As the Headmaster of Don Bosco, I am delighted to lead a team of skilled educators and curious learners. Our school is a space where students are inspired to explore, innovate, and achieve their best potential. We prioritize quality education, moral values, and co-curricular activities to ensure overall development.Let us continue to make learning an enriching and joyous experience.",
        regards:"Yours in education,",
        name:"Rev. Fr. Mohan Christuraj, SDB",
        position:"Headmaster (DBHSS)",
    },
    {
        id:4,
        image:"/Images/Common/Management/EnglishFather.jpg",
        from:"Principal",
        msgTitle:"Dear Students, Parents, and Staff,",
        message:"Welcome to Don Bosco School, where excellence is a tradition and education is a commitment. Our vision is to create a safe and nurturing environment where students excel academically, socially, and emotionally. We encourage creativity, collaboration, and resilience to prepare our students for the challenges of tomorrow.Together, let us uphold the legacy of Don Bosco and aim for greatness in all that we do.",
        regards:"Warm regards,",
        name:"Rev. Fr. Antony Joseph, SDB",
        position:"Principal (English Medium)",
    },
];
