export const DataBeyond = [
     {
        id:1,
        image:"/Images/Home/Beyond/House.jpg",
        title:"House Activities",
        link:"/house"
     },
 
     {
        id:2,
        image:"/Images/Home/Beyond/Group.jpg",
        title:"Group Activities",
        link:"/groups"
     },
     {
        id:3,
        image:"/Images/Home/Beyond/Sports.jpg",
        title:"Sports Activities",
        link:"/sports"
     },
     {
        id:4,
        image:"/Images/Home/Beyond/Celebration.jpg",
        title:"Our Events",
        link:"/tamil-celebrations"
     },
     {
      id:5,
      image:"/Images/Home/Beyond/Club.jpg",
      title:"Club Activities",
      link:"/tamil-clubs"
    },
    {
      id:6,
      image:"/Images/Home/Beyond/Skating.jpeg",
      title:"ExtraCurricular Activities",
      link:"/extracurricular"
    },
];
