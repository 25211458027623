const Entrance = () => {
  return (
    <>
      <p className='fw-bold'>ENTRANCE EXAM :</p>
      <ul className="lh-lg">
        <li>After Passing Entrance Exam, Only You Can continue the Admission in DON BOSCO.</li>
        <li>1 Standard to 10th Standard and 12th Standard students have Entrance Exam.</li>
        <li>11th Standard For Marksheet basis admission.</li>
      </ul>
    </>
  )
}

export default Entrance
